import React, { useState } from "react"
import { Link } from "gatsby"
import "./PropertyCard.scss"
import { ShowProcessedImage } from "../common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import GetGGFXImage from "../common/site/get-ggfx-image"
import NoImage from "../../images/no-image.png"
import { roundDecimal } from "../property-search/utils"
import { CurrConvertPrice } from '../../components/currency-converter/CurrConvertPrice'

import { UserObjectStoreProvider, SaveItem } from "@starberry/myaccount-website-utils"

const PropertyCard = props => {
  const propertyitem = props

  const [icon, setIcon] = useState(false)
  const showIcon = () => {
    setIcon(!icon)
  }
  return (
    <React.Fragment>
      <UserObjectStoreProvider>
        <div className="property-card img-zoom">
          <div className="properties-wrapper">
            <Link to={props.propertyDetailsLink} className="d-inline-block">
              <picture>
                {props.card_type == "similar_property" && props?.propertyImages?.length !== 0 ? (
                  <GetGGFXImage
                    imagename={"property.images.similarpropertytile"}
                    imagesources={
                      propertyitem.propertyImages &&
                      propertyitem.propertyImages[0]
                    }
                    fallbackalt={propertyitem.location}
                    imagetransformresult={props.processedImages}
                    id={props.propertyid}
                  />
                ) : propertyitem.propertyImages &&
                  propertyitem.propertyImages.length == 0 ? (
                  <img
                    src={NoImage}
                    alt={propertyitem.location + " - MCcone Properties"}
                  />
                ) : (
                  <ShowProcessedImage
                    images={
                      propertyitem.propertyImages &&
                      propertyitem.propertyImages[0]
                    }
                    attr={{
                      className: "mb-0 img-fluid",
                      alt: propertyitem.location + " - Mccone Properties",
                    }}
                    transforms={imageConfig.property.searchResults.sizes}
                  />
                )}
              </picture>
            </Link>
          </div>
          <div className="about-properties">
            <div className="properties-info">
              <div className="properties-heading">
                <Link to={props.propertyDetailsLink} className="d-inline-block">
                  {props.price_on_request == true ?
                  <p className="price-value">Ask for Price</p>
                  :
                  <p className="price-value">
                    {CurrConvertPrice(props.PropertyPrice)}
                    {props?.price && props.propertySearchType === "lettings" &&
                    props.priceQualifier
                      ? "/" + props.priceQualifier &&
                        props.priceQualifier.replace(/Price /g, "/")
                      : ""}
                  </p>
                  }
                </Link>
                {/* <i onClick={showIcon}  className={icon ? "icon-fill" : "icon-like"}></i> */}
                <SaveItem
                  type="property"
                  pid={props.propertyid}
                  userObjects={props.userObjects}
                >
                  <i className={"icon-like save-property"}></i>
                  <i className={"icon-fill remove-property"}></i>
                </SaveItem>
              </div>
              <p className="prop-result-description text-truncate">{propertyitem.description}</p>
              <p className="location">{propertyitem.location}</p>
            </div>

            <div className="properties-icons">
              <span className="text">
                <i className="icon-bedroom"></i>
                <span className="sm-text">{propertyitem.bedroomsCount}</span>
              </span>
              <span className="text">
                <i className="icon-bath"></i>
                <span className="sm-text">{propertyitem.bathroomsCount}</span>
              </span>
              {propertyitem.sqft && (
                <span className="text">
                  <i className="icon-size"></i>
                  <span className="sm-text">
                    {roundDecimal(propertyitem.sqft).toLocaleString()} sqft
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </UserObjectStoreProvider>
    </React.Fragment>
  )
}

export default PropertyCard
