import React, { useState } from 'react'
import Stars from '../stars';
import TimeAgo from 'react-timeago'

const ReviewCard = ({ review, formatter }) => {
 
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = (e) => {
        setIsReadMore(!isReadMore);
    };

return (
    <div className={"team_reviews_inner"} data={review.CreateTime}>
        <Stars count={review.starRating} />
        {review.comment && review.comment.length > 0 &&
            <p className="text">
            {isReadMore ? review.comment.slice(0, 200) : review.comment}
                <span onClick={() => toggleReadMore(review.id)} className="read-or-hide">
                {review.comment.length > 200 && <a href='javascript:;'>{isReadMore ? "...More" : " Less"}</a>}
                </span>
            </p>
        }
        <div className={review.comment.length > 200 ? "reviwer-section" : "reviwer-section margin-top"}>
            <div className="profile-review">
                <img src={review.reviewer.profilePhotoUrl}/>
            </div>
            <div className='profile-review-content'>
                <h5>{review.reviewer.displayName}</h5>
                <small><TimeAgo date={review.createTime} formatter={formatter} /></small>
            </div>
        </div>
    </div>
  )
}
export default ReviewCard;