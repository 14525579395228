import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import wordsToNumbers from 'words-to-numbers';
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { graphql, useStaticQuery } from 'gatsby';
import googleImg from "../../images/reviews/google.svg";
import CaretRight from "../../images/icon-right-blue.svg";
import ReviewCard from './ReviewCard';
import './TeamDetailReview.scss'
import { getitems } from '../utils/utils';

const TeamDetailReview = (props) => {

    const [testimonials, setTestimonials] = useState([]);
    const [overallReviews, setOverallReviews] = useState([]);
    const data = useStaticQuery(graphql`
    {
      glstrapi {
        teams(publicationState: LIVE) {
          Name
        }
      }
    }
  `)

    const AllPeople = data?.glstrapi?.teams;

    let stringDes = props.Designation;
    let Designation;
    if (typeof stringDes === 'string') {
      Designation = stringDes.trim();
    }

    const formatter = buildFormatter(frenchStrings)
    
    // Default latest reviews
    let slicedtestimonials = overallReviews.sort((a, b) => new Date(b.createTime) - new Date(a.createTime))

    var samReviews = [];
    var arsenReviews = [];
    var yasirReviews = [];
    if (slicedtestimonials && slicedtestimonials.length > 0) {
      slicedtestimonials.map(list => {
        let strTitle = list.comment;
        let Name;
        if(typeof strTitle === 'string') {
            Name = strTitle.includes('Sam')
            if (Name == true) {
              samReviews.push(list);
            }
        }
        if(typeof strTitle === 'string') {
          Name = strTitle.includes('Arsen')
          if (Name == true) {
            arsenReviews.push(list);
          }
        }
        if(typeof strTitle === 'string') {
          Name = strTitle.includes('Yassir')
          if (Name == true) {
            yasirReviews.push(list);
          }
        }
      })
    }

    var generalReviews = [...samReviews , ...arsenReviews, ...yasirReviews]

    var settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      mobileFirst: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1100,
          settings: {
            dots: true,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            dots: true,
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 2800,
          settings: {
            dots: true,
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    };

    useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC.replace('-dev', '')}/stb-google-reviews/google-review-reviews`
        getitems({url: url, setOverallReviews: setOverallReviews, setTestimonials: setTestimonials, teamName: props.TeamName, AllPeople: AllPeople});
    }, [])

    // Google review count
    var testimonial_count = overallReviews ? overallReviews.length : 0;
    var rating_count = 0;
    overallReviews && overallReviews.length > 0 && overallReviews.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating));
    })
    var rating_avg = rating_count / testimonial_count;

  return (
    <>
    {Designation == 'Managing Director' || Designation ==  'Managing Partner' || Designation == 'Property Consultant' || Designation == 'Lettings Consultant' || Designation == 'Associate Director' ?
    <div className={testimonials.length < 3 ? 'review-wrapper card_align' : 'review-wrapper'}>
      <Container>
          <div className={'team_detail_review'}>
              <div className='review_section_header col-lg-9 col-md-12'>
                  <h2>Client Reviews</h2>
                  <p>At McCone Properties, we are committed to our clients and believe that the true value of our services lies in the precise consulting we provide to our clients. Browse through the testimonials we have received from clients we have worked with.</p>
                  <div className="review-wrap">
                    <div className="img-wrap">
                      <img src={googleImg} alt="google" />
                    </div>
                    <span className="text-wrap">                       
                      Rated {parseFloat(rating_avg).toFixed(1)}/5 from {testimonial_count} Google Reviews
                    </span>
                  </div>
              </div>
              <div className="write-review">
                  <a href="https://g.page/r/CQWW0oxCTuyGEAg/review" className="btn btn-secondary" target="_blank">Leave a review <img src={CaretRight} alt="icon-right" /></a>
              </div>
          </div>
          <Slider {...settings}>
              {testimonials.length > 0 ? testimonials.map((review, i) => {
                  return(
                    <ReviewCard length={testimonials.length} review={review} formatter={formatter} />
                  )
              })
            : 
              generalReviews.slice(0, 10).map((review, i) => {
                return(
                    <ReviewCard review={review} formatter={formatter} />
                )
              })
            }
          </Slider>
      </Container>
    </div> :" "}
    </>
  )
}
export default TeamDetailReview;