import React from "react"
import { Link } from "gatsby"
import { useWindowSize } from "../../hooks/window-size"
import "./ButtonGroup.scss"

const TeamButtonGroup = ({ person }) => {
  const [windowWidth] = useWindowSize()
  const phoneNo = person.Phone;
  // const WhatsApp_No = phoneNo.replace(/[^\d]/g, '');
  const WhatsApp_No = '971585138750';
  return (
    <div className="team-cta">
      { windowWidth >= 992 && <div className="button-group">
        <Link href={"tel:" + person.Phone} className="btn">
          <i className="icon-dialer"></i>
          {windowWidth > 767 ? person.Phone : "Call"}
        </Link>
        <Link to={`/contact/?tid=${person.id}/`} className="btn">
          <i className="icon-envelope"></i>
          {windowWidth < 767 ? "Email" : "Email " + person.Name.split(" ")[0]}
        </Link>
        <Link href={`https://wa.me/+${WhatsApp_No}?text=Hi ${person.Name.split(" ")[0]},`} target="_blank" className="btn">
          <i className="icon-whatsapp"></i>
          {windowWidth < 767 ? "" : "Click to WhatsApp"}
        </Link>
      </div> }

      <div className="button-group-below">
        {person.LinkedIn && (
          <a className="social-cta" href={person.LinkedIn} target="_blank">
            <i className="icon-linkedin"></i>
            <span>LinkedIn</span>
          </a>
        )}
        {person.Facebook && (
          <a className="social-cta" href={person.Facebook} target="_blank">
          <i className="icon-fb"></i>
          <span>Facebook</span>
        </a>
        )}
        {person.Instagram && (
          <a className="social-cta" href={person.Instagram} target="_blank">
          <i className="icon-instagram"></i>
          <span>Instagram</span>
        </a>
        )}
      </div>
    </div>
  )
}

export default TeamButtonGroup
