/**
 * External dependencies
 */
import React from "react"

/**
 * Internal dependencies
 */

const Stars = (props) => {
  var count = 5
  if(props.count === "ONE") {
    count=1
  }
  if(props.count === "TWO") {
    count=2
  }
  if(props.count === "THREE") {
    count=3
  }
  if(props.count === "FOUR") {
    count=4
  }
  if(props.count === "FIVE") {
    count=5
  }
  const arrayOfZeros = new Array(count).fill(0);
  const Star = () => (
    <>
      {props?.reviewLength == 0 ? <i className="icon-star-grey"></i> : <i className="icon-star"></i>}
    </>
  )

  return (
    <div className="stars">
      {arrayOfZeros.map((_, index) => (
        <Star key={index} />
      ))}
    </div>
  )
}

export default Stars
