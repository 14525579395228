import React from "react"
import SEO from "../components/Seo/seo"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import ContactCard from "../components/ContactSidebar/ContactSidebarNoStaff"
import Header from "../components/Header/Header"
import CareersDetail from "../components/CareersDetail/CareersDetail"
import Footer from "../components/Footer/Footer"
import TeamDetail from "../components/TeamDetail/TeamDetail"

const TeamsDetailPage = ({data}) => {
  const team = data.glstrapi.team
  //console.log("team",team)
  let metatitle = ""+team.Name+" | "+team.Designation+" "
  let metadesc = "Get to know about "+team.Name+" here. Contact one of real estate brokers for assistance in finding the right properties for you."
  return (
    <div className="team-detail-page">
      <SEO
        title={team.Meta_Title ? team.Meta_Title : metatitle}
        description={team.Meta_Description ? team.Meta_Description : metadesc}
        image = {team?.Image?.url}
      />
      <Header alt/>
      <TeamDetail team={team}/>
      <div className="team-detail-footer">
      <Footer popularsearch= "Sale_Search"/>
      </div>
    </div>
  )
}

export default TeamsDetailPage

export const pageQuery = graphql`
query GetTeams($articleId: ID!) {
  glstrapi {
    team(id: $articleId, publicationState: LIVE) {
      id
      Background_and_Experience
      Designation
      URL
      Email
      Expertise
      Facebook
      GIF {
        url
      }
      Image {
        url
      }
      Instagram
      Key_Information
      LinkedIn
      Meta_Description
      Meta_Title
      Name
      Phone
      Tile_GIF {
        url
      }
      URL
      id
      imagetransforms
      languages
      select_categories {
        Name
      }
      sort_order
    }
  }
}
`