import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import propertyimg from "../../images/property-img.png";
import propertyTab01 from "../../images/tab-property01.png";
import propertyTab02 from "../../images/tab-property02.png";
import propertyDesk03 from "../../images/desk03.png";
import propertyDesk04 from "../../images/desk04.png";
import axios from 'axios';

import Slider from "react-slick";
import PropertyCard from "../PropertyCard/PropertyCard"

// import ScrollAnimation from 'react-animate-on-scroll';
import "./PropertyFeatures.scss";

const PropertyFeatures = (props) => {


  const [propItems, setPropItems] = useState([])
  const [proprentItems, setProprentItems] = useState([])
	const getitems = async url => {
		try {
			const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        }
      })
			if (data && data.length > 0) {
				setPropItems(data)
			}
		} catch (error) {
			console.error(error)
		}
	}
  // const getitemsrent = async url => {
	// 	try {
	// 		const { data } = await axios.get(url, {})
	// 		if (data && data.length > 0) {
	// 			setProprentItems(data)
	// 		}
	// 	} catch (error) {
	// 		console.error(error)
	// 	}
	// }

  useEffect(() => {
		//let url = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/Test?pid=" + props.id;
    //let area = props.area ?? "";
    let neg_email = props.neg_email ?? "";
    let url = process.env.GATSBY_STRAPI_SRC + "/properties?department=residential&_limit=-1&_sort=price:DESC&publish=true&ghost_property=false";
    if (neg_email) {
      url = url + "&negotiator_email_contains="+neg_email;
    }
		getitems(url)
    // let urlrent = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=lettings&_limit=4&_sort=price:DESC&publish=true";
    // if (neg_email) {
    //   urlrent = urlrent + "&negotiator_email_contains=" + neg_email;
    // }
		// getitemsrent(urlrent)
  },[])
  var settings = {
    dots: true,
    centerMode: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 2800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  return (
    <React.Fragment>
     {props.neg_email && propItems && propItems.length > 0 && 
     <section className="property-features staff-property">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="heading">My Properties</h2>
              <p className="similar--head">Or if you’d like to view more properties, view our <Link to="/property/for-sale/in-dubai/">properties for sale in Dubai</Link> or <Link to="/property/for-rent/in-dubai/">properties for rent in Dubai.</Link></p>
              <Tabs defaultActiveKey="first">
                <Tab eventKey="first">
                <Slider {...settings}>
                    {propItems && propItems.map((property, index) => {
                      let processedImages = JSON.stringify({});
                      if (property?.imagetransforms?.images_Transforms) {
                          processedImages = property.imagetransforms.images_Transforms;
                      }               
                      var uriStr = '';
                      if (property.department === 'residential') {
                          uriStr = property.search_type === "sales" ? '/property-for-sale/' : '/property-for-rent/';
                      } else if (property.department === 'commercial') {
                          uriStr = property.search_type === "sales" ? '/commercial-property-for-sale/' : '/commercial-property-for-rent/';
                      }
                      return(
                      <PropertyCard key={index} property={property} 
                        price={"AED " + property?.price?.toLocaleString()}
                        PropertyPrice={property?.price.toLocaleString()}
                        propertyid={property.id}
                        location={property?.address?.address}
                        description={property.description}
                        bedroomsCount={property.bedroom}
                        bathroomsCount={property.bathroom}
                        propertyImages={property.images}
                        propertyDetailsLink={`${uriStr}${property.slug}-${property.id}/`}
                        status={property.status}
                        sqft={property.size}
                        card_type="similar_property"
                        processedImages={processedImages}
                      />
                    )
                    })}
                  </Slider>
                </Tab>
                {/* <Tab eventKey="profile" title="property for rent in Dubai">
                <Slider {...settings}>
                    {proprentItems && proprentItems.map((property, index) => {
                      let processedImages = JSON.stringify({});
                      if (property?.imagetransforms?.images_Transforms) {
                          processedImages = property.imagetransforms.images_Transforms;
                      }               
                    var uriStr = property.search_type ==="sales"?'/property-for-sale/' :'property-for-rent/'
                    return(
                      <PropertyCard key={index} property={property} 
                        price={"AED " + property.price.toLocaleString()}
                        propertyid={property.id}
                        location={property.address.address1+', '+property.address.address2}                        description={property.description}
                        bedroomsCount={property.bedroom}
                        bathroomsCount={property.bathroom}
                        propertyImages={property.images}
                        propertyDetailsLink={`${uriStr}${property.slug}-${property.id}/`}
                        status={property.status}
                        sqft={property.size}
                        card_type="similar_property"
                        processedImages={processedImages}
                      />
                    )
                    })}
                  </Slider>
                </Tab> */}
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>}
    </React.Fragment>
  );
};

export default PropertyFeatures;
