import React from "react"
import currencyRate from "./currency.json"

export const CurrConvertPrice = (price) => {

  let priceStr = ''
  let values =   [{value:'AED', label:'AED'},{value:'GBP', label:'GBP'},   {value:'EUR', label:'EUR'},
    {value:'USD', label:'USD'},
    {value:'RUB', label:'RUB'},
    {value:'INR', label:'INR'}
  ]
  
  //get first currenct from env
  //if(typeof process.env?.GATSBY_CurrencyConverter !== undefined){
  //  values = process.env.GATSBY_CurrencyConverter.split(',');
  //}

  let currVal = values[0]
  
  //get localstorage value
  if (typeof window !== 'undefined') {
    if(localStorage.getItem('currTo') != null && localStorage.getItem('currTo') != currVal.value){ 
      // let cal//c
      return localStorage.getItem('currTo')+' '+parseInt(calcPrice(price,localStorage.getItem('currTo'),currVal.value)).toLocaleString()
    }
  }

  return currVal.value+' '+price;
}

function calcPrice(price,curr,defaultcurr) {
  return (currencyRate[curr] * ((currencyRate['GBP']/currencyRate[defaultcurr]) * parseInt(price?.replaceAll(',', ''))) )
}