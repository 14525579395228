import React from "react"
import { useWindowSize } from "../../hooks/window-size"
import parse from "html-react-parser"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./TeamDetail.scss"
import TeamButtonGroup from "../TeamButtonGroup/TeamButtonGroup"
import StaffProperty from "../PropertyFeatures/StaffProperty"
import Help from "../Help/Help"
import SiteBreadcrumbs from "../BreadCrumbs/site-breadcrumbs"
import Sticky from 'react-stickynode';
import TeamDetailReview from "./TeamDetailReview"

const TeamDetail = ({ team }) => {
  const [windowWidth] = useWindowSize()
  const phoneNo = team.Phone;
  // const WhatsApp_No = phoneNo.replace(/[^\d]/g, '');
  const WhatsApp_No = '971585138750';

  let processedImages = JSON.stringify({})
  if (team?.imagetransforms?.Image_Transforms) {
    processedImages = team.imagetransforms.Image_Transforms
  }

  return (
    <div className="team-detail-wrapper ">
      <div className="full-gif">
        {windowWidth < 768 && <img src={team?.GIF?.url} alt={team?.Name} />}
        {/* {(windowWidth < 992 && windowWidth >= 768) && <GetGGFXImage
        imagename={"teams.Image.bannerimg"}
        imagesources={team.Image}
        fallbackalt={team.Name + " - " + team.Designation}
        imagetransformresult={processedImages}
        id={team.id}
      />} */}
      </div>
      <div className="team-wrapper">
        <div className="team-detail-container container">
          <Row>
            <Col md={12} lg={8} xl={8}>
              <div className="team-details">
                <div className="team-content">
                  <div>
                    <SiteBreadcrumbs style="grey-breadcrumbs" baselabel="Meet the team" baseurl="/about/meet-the-team/" menulabel={team.Name}/>
                    <h1 className="name">{team.Name}</h1>
                    <p className="designation">{team.Designation}</p>
                    <div className="ctas">
                      <TeamButtonGroup person={team} />
                    </div>
                    <div className="bar"></div>
                    <div className="key-info">
                      {team.Key_Information && parse(team.Key_Information)}
                    </div>
                  </div>
                  {windowWidth >= 768 && windowWidth < 992 && (
                    <div className="team-image">
                      <img src={team.GIF.url} alt={team.Name}></img>
                    </div>
                  )}
                </div>

                <div className="bar"></div>
                <div className="background">
                  {team.Background_and_Experience &&
                    parse(team.Background_and_Experience)}
                </div>
                <div className="expertise">
                  {team.Expertise && parse(team.Expertise)}
                </div>
                <div className="stop-sticky"></div>
              </div>
            </Col>
            <Col lg={4} xl={4}>
              {windowWidth >= 992 && (
                <Sticky top=".header" bottomBoundary=".stop-sticky">
                  <div className="team-image">
                    <img src={team.GIF.url} alt={team.Name}></img>
                  </div>
                </Sticky>
              )}
            </Col>
          </Row>
        </div>
        <TeamDetailReview TeamName={team.Name} Designation={team.Designation}/>
        <StaffProperty neg_email={team.Email}/>
      </div>

      <Help module="Lets_Work_Together_Module" />
      {windowWidth < 992 && (
        <div className="sticky-button-group team-detail-sticky">
          <Link href={"tel:" + team.Phone} className="btn">
            <i className="icon-dialer"></i>
            {windowWidth > 767 ? team.Phone : "Call"}
          </Link>
          <Link to={`/contact/?tid=${team.id}/`} className="btn">
            <i className="icon-envelope"></i>
            {windowWidth < 767 ? "Email" : "Email " + team.Name.split(" ")[0]}
          </Link>
          <Link href={`https://wa.me/+${WhatsApp_No}?text=Hi ${team.Name.split(" ")[0]},`} target="_blank" className="btn">
            <i className="icon-whatsapp"></i>
            {windowWidth < 767 ? "" : "Click to WhatsApp"}
          </Link>
        </div>
      )}
    </div>
  )
}

export default TeamDetail
